import React, { useEffect, useState } from "react";
import axios from "axios";
import Title from "../../Elements/Title";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const QuestionDisplay = ({ definition, allAnswers, selectedAnswers }) => {
    return (
        <div>
            <Title variant="h5">Frage: {definition.name}</Title>
            <p>{definition.text}</p>
            {selectedAnswers && (
                <div>
                    <Title variant="h5">Ausgewählte Antworten</Title>
                    {selectedAnswers.map((answer, index) => (
                        <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <CheckIcon
                                sx={{
                                    fontSize: 15,
                                    color: "#3f51b5",
                                    marginRight: "5px",
                                }}
                            />
                            <span>{answer.text}</span>
                        </div>
                    ))}
                </div>
            )}
            <div>
                <Title variant="h5">Mögliche Antworten</Title>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {allAnswers?.map((answer, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "15px",
                                marginBottom: "5px",
                            }}
                        >
                            <RadioButtonUncheckedIcon
                                sx={{
                                    fontSize: 10,
                                    color: "#3f51b5",
                                    marginRight: "5px",
                                }}
                            />
                            <span style={{ color: "grey" }}>{answer.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const QuestionView = (props) => {
    const [question, setQuestion] = useState();
    const [definition, setDefinition] = useState();
    const [selectedAnswers, setSelectedAnswers] = useState();
    const [allAnswers, setAllAnswers] = useState();

    useEffect(() => {
        const fetchData = async () => {
            if (!props.questionUrl || question) return;

            try {
                const questionResponse = await axios.get(props.questionUrl);
                const questionData = questionResponse.data;
                setQuestion(questionData);

                const definitionResponse = await axios.get(
                    questionData.definition
                );
                const definitionData = definitionResponse.data;
                setDefinition(definitionData);

                const possibleAnswersPromises = definitionData.answers.map(
                    (answer) => axios.get(answer)
                );
                const possibleAnswersResponses = await Promise.all(
                    possibleAnswersPromises
                );
                const possibleAnswersElements = possibleAnswersResponses.map(
                    (response) => response.data
                );
                setAllAnswers(possibleAnswersElements);

                const selectedAnswersResponse = await axios.get(
                    questionData.selected_answers_set
                );
                const selectedAnswersData = selectedAnswersResponse.data;
                const selectedAnswersPromises = selectedAnswersData.map(
                    (answer) => axios.get(answer.answer_option)
                );
                const selectedAnswersResponses = await Promise.all(
                    selectedAnswersPromises
                );
                const selectedAnswersElements = selectedAnswersResponses.map(
                    (response) => ({ ...response.data })
                );
                setSelectedAnswers(selectedAnswersElements);
            } catch (error) {
                console.error("Error fetching question data:", error);
                // Handle error appropriately, e.g., set an error state
            }
        };

        fetchData();
    }, [props.questionUrl, question]);

    if (definition && allAnswers) {
        return (
            <QuestionDisplay
                definition={definition}
                allAnswers={allAnswers}
                selectedAnswers={selectedAnswers}
            />
        );
    }

    function formatDate(dateString) {
        return new Date(dateString).toLocaleDateString("de-CH");
    }
};
