import axios from "axios";
import { requestService } from "./requestService";

// this is an enum in the backend, we use this to tell the backend whether the accept was made by the doctor or the system itself
const DOCTOR_GUIDED = 1;

export const childrenService = {
    getChildren,
    getChild,
    getInterventions,
    getInterventionHistory,
    calculateRecommendations,
    classifyIntervention,
    reclassifyIntervention,
    archiveMessage,
    getAllActivityLogs,
    getRhythmLog,
    getRhythmPropositionLogs,
    saveDoctorsNote,
    getAllMessagesToParent,
    sendMessageToParents,
    resetTodo,
    getQuestionnaires,
    getChildPhase,
    getChildNeedForSleep,
    getChildWellness,
    getValidDays,
    getTherapy,
    getAllChildInterventions,
};

// CHILD
/**
 * Fetches all children from the server.
 * @async
 * @returns {Promise<Array>} The array of children data.
 */
async function getChildren() {
    const url = localStorage.getItem("childrenUrl");
    const config = requestService.authRequestConfig();
    const response = await axios.get(url, config);
    return response.data;
}

/**
 * Saves the doctor's note for a specific child.
 * @async
 * @param {string} childUrl - The URL of the child's data.
 * @param {string} updatedNote - The updated doctor's note.
 * @returns {Promise<number>} The status code of the response.
 */
async function saveDoctorsNote(childUrl, updatedNote) {
    let config = requestService.authRequestConfig();
    const data = { doctors_notes: updatedNote };
    let response = await axios.patch(childUrl, data, config);
    return response.status;
}

/**
 * Resets the todo status for a child.
 * @async
 * @param {Object} child - The child object.
 * @returns {Promise<Object>} The response from the server.
 */
async function resetTodo(child) {
    child["todo"] = 0;
    let config = requestService.authRequestConfig();
    return await axios.patch(child["url"], child, config);
}

/**
 * Archives a message.
 * @async
 * @param {Object} message - The message object to be archived.
 * @returns {Promise<Object>} The response from the server.
 */
async function archiveMessage(message) {
    let config = requestService.authRequestConfig();
    return await axios.patch(message["url"], message, config);
}

/**
 * Sends a message to parents.
 * @async
 * @param {string} messageToParentsUrl - The URL to send messages to parents.
 * @param {Object} message - The message object to be sent.
 * @returns {Promise<Object>} The response from the server.
 */
async function sendMessageToParents(messageToParentsUrl, message) {
    let config = requestService.authRequestConfig();
    return await axios.post(messageToParentsUrl, message, config);
}

/**
 * Fetches detailed information about a specific child.
 * @async
 * @param {string} childId - The ID of the child.
 * @returns {Promise<Object>} The child's data including caretaker information.
 */
async function getChild(childId) {
    const childrenUrl = localStorage.getItem("childrenUrl");
    let config = requestService.authRequestConfig();
    let response = await axios.get(childrenUrl, config);

    const relevantChild = response.data.filter(
        (child) => child.id.toString() === childId
    )[0];
    const childUrl = relevantChild["url"];

    const allCaretakerNames = [];
    const allCaretakerEmails = [];
    const allCaretakerWellnessURLs = [];
    for await (const caretaker of relevantChild["caretakers"]) {
        const response = await axios.get(caretaker["url"], config);
        allCaretakerNames.push(
            response.data.first_name + " " + response.data.last_name
        );
        allCaretakerEmails.push(response.data.email);
        allCaretakerWellnessURLs.push(response.data["wellness"]);
    }

    config = requestService.authRequestConfig();
    response = await axios.get(childUrl, config);
    response.data["caretakers_names"] = allCaretakerNames;
    response.data["caretakers_emails"] = allCaretakerEmails;
    response.data["caretakers_wellness_urls"] = allCaretakerWellnessURLs;
    return response.data;
}

// LOGS
/**
 * Fetches all activity logs for a child.
 * @async
 * @param {string} cryLogEntriesUrl - The URL for cry log entries.
 * @param {string} sleepLogEntriesUrl - The URL for sleep log entries.
 * @param {string} mealLogEntriesUrl - The URL for meal log entries.
 * @returns {Promise<Object>} An object containing all types of logs.
 */
async function getAllActivityLogs(
    cryLogEntriesUrl,
    sleepLogEntriesUrl,
    mealLogEntriesUrl
) {
    const [cryLogs, mealLogs, sleepLogs] = await Promise.all([
        getCryLogs(cryLogEntriesUrl),
        getMealLogs(mealLogEntriesUrl),
        getSleepLogs(sleepLogEntriesUrl),
    ]);
    return {
        cryLogs: cryLogs,
        mealLogs: mealLogs,
        sleepLogs: sleepLogs,
    };
}

/**
 * Fetches cry logs.
 * @async
 * @param {string} cryLogEntriesUrl - The URL for cry log entries.
 * @returns {Promise<Array>} The array of cry log data.
 */
async function getCryLogs(cryLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(cryLogEntriesUrl, config);
    return response.data;
}

/**
 * Fetches meal logs.
 * @async
 * @param {string} mealLogEntriesUrl - The URL for meal log entries.
 * @returns {Promise<Array>} The array of meal log data.
 */
async function getMealLogs(mealLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(mealLogEntriesUrl, config);
    return response.data;
}

/**
 * Fetches sleep logs.
 * @async
 * @param {string} sleepLogEntriesUrl - The URL for sleep log entries.
 * @returns {Promise<Array>} The array of sleep log data.
 */
async function getSleepLogs(sleepLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(sleepLogEntriesUrl, config);
    return response.data;
}

/**
 * Fetches rhythm log data.
 * @async
 * @param {string} rhythmLogUrl - The URL for rhythm log entries.
 * @returns {Promise<Object>} An object containing all logs and creation time.
 */
async function getRhythmLog(rhythmLogUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(rhythmLogUrl, config);
    if (!response || response.data.length === 0)
        return { all_logs: [], create_time: null };
    const createTime = response.data[0].create_time;
    const allLogs = [];
    for await (const logUrl of response.data[0]["logs"]) {
        const response = await axios.get(logUrl, config);
        allLogs.push(response.data);
    }

    return { all_logs: allLogs, create_time: createTime };
}

/**
 * Fetches rhythm proposition logs.
 * @async
 * @param {string} rhythmPropositionLogsUrl - The URL for rhythm proposition logs.
 * @returns {Promise<Object>} An object containing all logs and creation time.
 */
async function getRhythmPropositionLogs(rhythmPropositionLogsUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(rhythmPropositionLogsUrl, config);
    if (!response || response.data.length === 0)
        return { all_logs: [], create_time: null };
    const createTime = new Date();

    return { all_logs: response.data?.logs || [], create_time: createTime };
}

// INTERVENTIONS
/**
 * Fetches interventions and their definitions.
 * @async
 * @param {string} interventionListUrl - The URL for the intervention list.
 * @param {string} allInterventionDefinitionsUrl - The URL for all intervention definitions.
 * @returns {Promise<Object>} An object containing all intervention definitions and active interventions.
 */
async function getInterventions(
    interventionListUrl,
    allInterventionDefinitionsUrl
) {
    const config = requestService.authRequestConfig();

    let responseInterventionListData;

    const responseInterventionList = await axios.get(
        interventionListUrl,
        config
    );
    responseInterventionListData = responseInterventionList["data"];

    const allInterventionDefinitions = await axios.get(
        allInterventionDefinitionsUrl
    );

    let activeInterventions = [];
    for (let i = 0; i < responseInterventionListData.length; i++) {
        const interventionItem = responseInterventionListData[i];
        const interventionDefinitionUrl =
            interventionItem["intervention_definition"];
        const responseInterventionDefinition = await axios.get(
            interventionDefinitionUrl,
            config
        );
        responseInterventionDefinition["data"]["status"] =
            interventionItem["status"];
        responseInterventionDefinition["data"]["url"] = interventionItem["url"];
        responseInterventionDefinition["data"]["status_date"] =
            interventionItem["status_date"];
        responseInterventionDefinition["data"]["active_time_in_days"] =
            interventionItem["active_time_in_days"];
        responseInterventionDefinition["data"]["intervention_definition_url"] =
            interventionItem["intervention_definition"];
        responseInterventionDefinition["data"]["intervention_url"] =
            interventionItem["url"];
        activeInterventions.push(responseInterventionDefinition["data"]);
    }

    return {
        allInterventionDefinitions: allInterventionDefinitions.data.result,
        activeInterventions: activeInterventions,
    };
}

/**
 * Fetches the history of an intervention.
 * @async
 * @param {string} interventionUrl - The URL of the intervention.
 * @returns {Promise<Array>} The intervention history data.
 */
async function getInterventionHistory(interventionUrl) {
    const config = requestService.authRequestConfig();

    const responseInterventionHistory = await axios.get(
        `${interventionUrl}/history`,
        config
    );
    return responseInterventionHistory["data"];
}

/**
 * Calculates recommendations.
 * @async
 * @param {string} calculationUrl - The URL for calculation.
 * @returns {Promise<void>}
 */
async function calculateRecommendations(calculationUrl) {
    const config = requestService.authRequestConfig();
    let body = {
        datetime: new Date().toISOString(),
    };
    await axios.post(calculationUrl, body, config);
}

/**
 * Fetches all messages to parents.
 * @async
 * @param {string} messageToParentsUrl - The URL for messages to parents.
 * @returns {Promise<Array>} The array of messages to parents.
 */
async function getAllMessagesToParent(messageToParentsUrl) {
    const config = requestService.authRequestConfig();
    const messageToParentsList = await axios.get(messageToParentsUrl, config);
    return messageToParentsList.data;
}

/**
 * Classifies an intervention.
 * @async
 * @param {string} interventionUrl - The URL of the intervention.
 * @param {string} newStatus - The new status for the intervention.
 * @param {Object} interventionDefinition - The intervention definition object.
 * @param {Object} child - The child object.
 * @returns {Promise<Object>} The response data from the server.
 */
async function classifyIntervention(
    interventionUrl,
    newStatus,
    interventionDefinition,
    child
) {
    try {
        const data = {
            child: child,
            status: newStatus,
            status_date: new Date().toISOString(),
            result: 0,
            intervention_definition: interventionDefinition["url"],
            therapy_style: DOCTOR_GUIDED,
        };

        const config = requestService.authRequestConfig();
        const response = await axios.post(interventionUrl, data, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Reclassifies an intervention.
 * @async
 * @param {string} interventionUrl - The URL of the intervention.
 * @param {string} newStatus - The new status for the intervention.
 * @returns {Promise<Object>} The response data from the server.
 */
async function reclassifyIntervention(interventionUrl, newStatus) {
    try {
        const data = { status: newStatus };
        const config = requestService.authRequestConfig();
        const response = await axios.patch(interventionUrl, data, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches questionnaires.
 * @async
 * @param {string} questionnaireUrl - The URL for questionnaires.
 * @returns {Promise<Object>} The questionnaire data.
 */
async function getQuestionnaires(questionnaireUrl) {
    try {
        const config = requestService.authRequestConfig();
        const response = await axios.get(questionnaireUrl, config);
        console.log("QUESTIONNAIRE RESPONSE", response);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches the child's phase.
 * @async
 * @param {string} childPhaseUrl - The URL for the child's phase.
 * @returns {Promise<Object>} The child's phase data.
 */
async function getChildPhase(childPhaseUrl) {
    try {
        const config = requestService.authRequestConfig();
        const response = await axios.get(childPhaseUrl, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches the child's need for sleep.
 * @async
 * @param {string} needForSleepUrl - The URL for the child's need for sleep.
 * @returns {Promise<Object>} The child's need for sleep data.
 */
async function getChildNeedForSleep(needForSleepUrl) {
    try {
        const response = await axios.get(needForSleepUrl);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches the child's wellness data.
 * @async
 * @param {string} childWellnessURL - The URL for the child's wellness data.
 * @returns {Promise<Object>} The child's wellness data.
 */
async function getChildWellness(childWellnessURL) {
    try {
        const response = await axios.get(childWellnessURL);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches valid days within a date range.
 * @async
 * @param {string} validDaysURL - The URL for valid days.
 * @param {Date} begin - The start date.
 * @param {Date} end - The end date.
 * @returns {Promise<Array>} The array of valid days.
 */
async function getValidDays(validDaysURL, begin, end) {
    const config = requestService.authRequestConfig();
    config["params"] = {
        from_day: begin.toISOString(),
        to_day: end.toISOString(),
    };
    try {
        const response = await axios.get(validDaysURL, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Fetches therapy data for a child.
 * @async
 * @param {string} childUrl - The URL of the child's data.
 * @returns {Promise<Object>} The therapy data for the child.
 */
async function getTherapy(childUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(`${childUrl}/therapy`, config);
    console.log("Therapy Request", response);
    return response.data;
}

async function getAllChildInterventions(childUrl) {
    try {
        const config = requestService.authRequestConfig();
        const response = await axios.get(
            `${childUrl}/interventions/all`,
            config
        );
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
