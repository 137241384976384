import { useState } from "react";
import { Typography, Box, Button, Dialog, List, ListItem } from "@mui/material";

export default function InterventionsOverview(props) {
    const { interventionsHistory } = props;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const getStatus = (statusInt) => {
        switch (statusInt) {
            case 0:
                return "OPEN";
            case 1:
                return "ACCEPTED";
            case 2:
                return "UNSUITABLE";
            case 3:
                return "REJECTED";
            case 4:
                return "EXPIRED";
            default:
                return "UNKNOWN STATUS";
        }
    };

    return (
        <div>
            <Typography variant="h6">Interventions Overview</Typography>
            <Button onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? "Hide Interventions" : "Show Interventions"}
            </Button>
            <Dialog
                open={isCollapsed}
                onClose={() => setIsCollapsed(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth
            >
                <Box>
                    <Typography variant="h6">Interventions Overview</Typography>
                    <List>
                        {interventionsHistory.reverse().map((intervention) => (
                            <ListItem
                                key={intervention.id}
                                style={{
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "8px",
                                    padding: "16px",
                                    margin: "8px 0",
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            "&:hover": {
                                                position: "relative",
                                                "&::after": {
                                                    content: `"${intervention.enriched_title}"`,
                                                    position: "absolute",
                                                    left: 0,
                                                    top: "100%",
                                                    backgroundColor: "white",
                                                    padding: "8px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    boxShadow:
                                                        "0 2px 4px rgba(0,0,0,0.1)",
                                                    zIndex: 1000,
                                                    maxWidth: "80dvw",
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                },
                                            },
                                        }}
                                    >
                                        {intervention?.enriched_title
                                            ? `${intervention.enriched_title.substring(0, 30)}...`
                                            : "No enriched title"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            "&:hover": {
                                                position: "relative",
                                                "&::after": {
                                                    content: `"${intervention.enriched_text}"`,
                                                    position: "absolute",
                                                    left: 0,
                                                    top: "100%",
                                                    backgroundColor: "white",
                                                    padding: "8px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    boxShadow:
                                                        "0 2px 4px rgba(0,0,0,0.1)",
                                                    zIndex: 1000,
                                                    maxWidth: "80dvw",
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                },
                                            },
                                        }}
                                    >
                                        {intervention?.enriched_text
                                            ? `${intervention.enriched_text?.substring(
                                                  0,
                                                  30
                                              )}...`
                                            : "No enriched text"}
                                    </Typography>
                                    <Typography>
                                        Status:{" "}
                                        {getStatus(intervention?.status || -1)}
                                    </Typography>
                                    <Typography>
                                        Result: {intervention.result}
                                    </Typography>
                                    <Typography>
                                        User Feedback:{" "}
                                        {intervention.user_feedback}
                                    </Typography>
                                    <Typography>
                                        Definition ID:{" "}
                                        {intervention.intervention_definition
                                            .split("/")
                                            .pop()}
                                    </Typography>
                                    <Typography>
                                        ID: {intervention.url.split("/").pop()}
                                    </Typography>
                                    <Typography>
                                        Reset Time:{" "}
                                        {new Date(
                                            intervention.reset_timestamp
                                        ).toLocaleString()}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Dialog>
        </div>
    );
}
